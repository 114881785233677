@font-face {
  font-family: 'Paris Avenue';
  src: url('./assets/fonts/Paris\ Avenue.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham/Gotham\ Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham/Gotham\ Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham/Gotham\ Bold\ Italic.ttf') format('opentype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham/Gotham\ Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham/Gotham\ Thin.otf') format('opentype');
  font-weight: thin;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham/Gotham\ Thin\ Italic.otf') format('opentype');
  font-weight: thin;
  font-style: italic;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



body {
  margin: 0;
  font-family:  'Gotham', 'Paris Avenue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
